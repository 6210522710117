$mainFont: "Nunito";//"Patrick Hand SC";
$secondaryFont:"Lora"; //"Roboto Slab";
$stylishFont:"Windsong";
$f12: 12px;
$f14: 14px;
$f16: 16px;
$f18: 18px;
$f20: 20px;
$f22: 22px;
$f24: 24px;
$f26: 26px;
$f28: 28px;
$f30: 30px;
$f48: 48px;
$f72: 72px;
$f100: 100px;
$f144: 144px;