@use "../abstract" as *;

.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
    width: 100%;
    overflow: visible;
    margin: 0;
    padding: 0;
    background-color: $lightGray;
}

.logoWrapper{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    box-sizing: border-box;
    overflow: visible;
    width: 60vw;
    height: 100vh;
    padding: 3% 3% 3% 0%;
    margin-left: -20vw;
    z-index: 0;

    @include logo(1vw, $darkBlue, 'true');

}


@media only screen and (max-width:$smallScreen){
    
    .logoWrapper{
        justify-content: center;
        width: 90vw;
        margin-left: -15vw;
        @include logo(1.2vw, $darkBlue, 'true')
    }
}

@media only screen and (max-width:$tablet){
    .logoWrapper{
        justify-content: center;
        width: 90vw;
        margin: auto;
        padding: 3%;
        @include logo(2vw, $darkBlue, 'true');
    }
}

@media only screen and (max-width:$smallTablet){
    .logoWrapper{
        justify-content: center;
        width: 100vw;
        margin: auto;
        padding: 3%;
        @include logo(2vw, $darkBlue, 'true');
    }
}