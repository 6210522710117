$darkButtonShadow: #000000;
$lightButtonShadow: rgba(222, 200, 163, 0.5);
$darkBlue: #082453;
$midBlue: #15418A;
$lightBlue: #3072DF;
$yellow: #DEC8A3;
$red: #b30d0b;
$yellow-l1: lighten($yellow, 6%);
$yellow-l2: lighten($yellow, 12%);
$yellow-l3: lighten($yellow, 18%);
$darkGray: #B3B3B3;
$lightGray: #E6E6E6;
$white: #FFFFFF;