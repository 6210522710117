@use "../abstract/" as *;

.aboutWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    min-width: 320px;
    font-family: $mainFont; 
    color: $darkBlue;
}

.greeting{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: $f26;
    margin: 0 0 35px 0;
    line-height: 1.5em;
    .greetingLineContainer{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
}

.introduction{
    width: 100%;
    min-width: 320px;
    margin-bottom: 4rem;
    white-space: normal;
    font-family: $secondaryFont;
    font-size: $f16;
    text-align: justify;
    line-height: 1.8em;
    
    .skills{
        display: flex;
        flex-direction: row;
        gap: 10%;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        .skillWrapper{
            display: flex;
        }
    }
    ul{
        list-style: none;
        margin: 35px 10% 0 0;
        padding: 0;
        li{
            white-space: nowrap;
        }
    }
}

@mixin mobile {
    .greeting{
        font-size: $f26;

        .greetingLineContainer{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
        }
    }

    .introduction{
        font-size: $f16;
        text-align: justify;
        .skills{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0;
            margin: 2rem 0 0 0;
            padding: 0;
            ul{margin: 0;}
        }
    }
}

.mobile{
    @include mobile();
}

@media only screen and (max-width:$tablet){
    @include mobile();
}