@use "./abstract/" as *;

.container{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .caption{
        font-size: $f72;
        font-weight: 500;
        color: $darkGray;
        font-family: $stylishFont;
        margin-bottom: 30px;
    }
    
    .desc{
        color: $darkBlue;
        font-size: $f20;

    }
    .link{
        text-decoration: underline;
        cursor: pointer;
    }
}