@use "../abstract" as *;

.footerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: $darkBlue;
}

.lineContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footerText{
    font-family: $mainFont;
    font-size: $f14;
    color: $lightGray;
}

.addBlank{
    padding-left: 7px;
}

@media only screen and (max-width:$tablet){
    .footerContainer{
        flex-direction: column;
        height: 100px;
    }
}
