@use "../styles/abstract/" as *;


// /* latin */
// @font-face {
//   font-family: 'Patrick Hand SC';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(../assets/fonts/0nkwC9f7MfsBiWcLtY65AWDK873ljiK7.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// /* latin */
// @font-face {
//   font-family: 'Roboto Slab';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url(../assets/fonts/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmb2Rj.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

/* latin */
@font-face {
  font-family: 'WindSong';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KR1WBsyu-P-GFEW57o9-F9A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'WindSong';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KR1RBsyu-P-GFEW57oeNNMW1nyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTQ3jw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/0QI6MX1D_JOuGQbT0gvTJPa787weuxJBkq18m9eY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
  }

/* Track */
::-webkit-scrollbar-track {
background: $lightGray;
border: 1px solid $darkGray;
border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: $darkBlue;
border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: $lightBlue;
}

/* width */
:-webkit-scrollbar {
    width: 12px;
  }

/* Track */
:-webkit-scrollbar-track {
background: $lightGray;
border: 1px solid $darkGray;
border-radius: 5px;
}

/* Handle */
:-webkit-scrollbar-thumb {
background: $darkBlue;
border-radius: 5px;
}

/* Handle on hover */
:-webkit-scrollbar-thumb:hover {
background: $lightBlue;
}

:root{
    margin:0;
    padding:0;
    scrollbar-color: $darkBlue $lightGray;
    scrollbar-width: thin;
    font-family: $mainFont;
}

body{
    margin:0;
    padding: 0;
    scroll-behavior: smooth;
}

input{
  height: 2em;
}

textarea{
  display: block;
  resize:none;
  height: 10em;
}

#footerLogo{
  padding-left: 5px;
}

#aboutLogo{
  padding-left: 8px;
}

#topNavigationLogo{
  opacity: 0;
}

.verticalCaptionText{
  font-family: $stylishFont;
  box-sizing: border-box;
  width: 100%;
  font-weight: 400;
  font-size: 144px;
  writing-mode:vertical-lr;
  transform: scale(-1);
  line-height: 1em;
  margin: 0;
  padding-bottom: 0.42em;
}

.horizontalCaptionText{
  font-family: $stylishFont;
  box-sizing: border-box;
  width: 100%;
  font-weight: 400;
  font-size: 56px;
  line-height: 1em;
  margin: 0;
  padding: 0 0.4em 0 0;
}

.lightCaption{
  color:$lightGray;
}

.darkCaption{
  color:$darkGray;
}